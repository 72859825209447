<template>
  <div>

    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>

    <div class="row">
      <div
        class="flex xs12"
        :class="{'sm4': !!method.regions}"
      >
        <va-card :title="$t('methods.view')">
          <actions
            slot="actions"
            crud-links="evangelisticMethods"
            controller="EvangelisticMethods"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          />
          <text-list :title="$t('tables.headings.name')">
            {{ $t(method.name) }}
          </text-list>
        </va-card>
      </div>
      <div
        class="flex xs12 sm8"
        v-if="!!method.regions"
      >
        <va-card :title="$t('regions.index')">
          <local-table
            crud-links="regions"
            :editable="true"
            :top-options="[]"
            :action-options="['view', 'edit']"
            :columns="regionsFields"
            :data="regions"
            :loading="loading"
            @view-item="$router.push({ name: 'regionsView', params: { id: $event.id }})"
            @edit-item="$router.push({ name: 'regionsEdit', params: { id: $event.id }})"
            @search-data="filterSearch"
          />
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const LocalTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/LocalTable')
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'methods-view',
  components: {
    Actions, LocalTable, TextList,
  },
  data () {
    return {
      error: false,
      loading: false,
      method: {},
      regions: [],
      actions: ['index', 'new', 'edit', 'delete'],
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    regionsFields () {
      return [
        {
          name: 'name',
          title: this.$t('tables.headings.region'),
          callback: this.translatedName,
        },
        {
          name: '__slot:actions',
          visible: this.regions.length > 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    translatedName (name) {
      return this.$t(name)
    },
    routeBuilder (id) {
      return `evangelistic-methods/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const methodId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(methodId))
      } catch (err) {
        // console.log('Error fetching method data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.method = { ...u.data.data }
      if (this.method.regions) {
        this.regions = this.method.regions.slice(0)
      }
    },
    filterSearch (query) {
      if (query === '') {
        const methodRegions = this.method.regions || []
        this.regions = methodRegions.slice(0)
        return
      }
      this.regions = this.regions.filter(c => {
        let search = c.name.indexOf(query) > -1
        if (c.manager) {
          search = search || c.manager.name.indexOf(query) > -1
        }
        return search
      }).slice(0)
    },
  },
}
</script>
